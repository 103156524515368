import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'How we contribute to the solution',
    paragraph: 'At AiView, we make use of technologies like Computer Vision, Artificial Intelligence and Voice Assistance to develop a wearable device that serves the visually impaired and makes their life a tad bit easier. Our goal is to make the user fully aware of the surroundings, while also solving the biggest challenge all visually impaired people face, navigation in open areas. Mentioned below are the key areas we work on.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Navigation in Open Spaces
                </div>
                <h3 className="mt-0 mb-12">
                  Obstacle Detection and Navigation
                </h3>
                <p className="m-0">
                Our navigation software provides real-time assistance to walk independently. Its working involves the detection of obstacles like potholes, animals and traffic lights through computer vision and measurement of various parameters to accurately guide the user along the safest path.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/img_navigation.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Safety
                </div>
                <h3 className="mt-0 mb-12">
                  Real Time Object Recognition
                </h3>
                <p className="m-0">
                  Users can recognize a wide variety of objects in real time using the object recognition software. The software can detect and automatically scan barcodes of products, count money, detect color, and do much more. The software is linked to the voice assistant for better user experience.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/img_objdetect.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Virtual Cognizance
                </div>
                <h3 className="mt-0 mb-12">
                  Image Captioning
                </h3>
                <p className="m-0">
                  Image captioning software allows users to pass a voice command to the system asking for what is happening infront of them. The device then captures an image and turns it into an audio caption which best describes the situation.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/img_imgcap.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Social Inclusion
                </div>
                <h3 className="mt-0 mb-12">
                  Facial Detection and Recognition
                </h3>
                <p className="m-0">
                  Our facial detection software provides support for identifying pre-recognized saved faces and also detecting other faces in the frame. It provides data such as the age, gender and mood of the person in front of them. This helps them to be socially inclusive.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/img_facerecognition.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Documents and Text based material
                </div>
                <h3 className="mt-0 mb-12">
                  Text Recognition and Audio Output
                </h3>
                <p className="m-0">
                  With a single voice command, the device captures an image of the document or any surface with text and extracts the data from it. This text is then analyzed, cleaned and converted into readable format which is read out by the voice assistant to the user. The software can read text from blackboards, sign boards, bill boards, newspapers and any kind of document, form or even a book.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/img_ocr.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Independence
                </div>
                <h3 className="mt-0 mb-12">
                  Personal Voice Assistant
                </h3>
                <p className="m-0">
                  The above mentioned features are controlled by the heart of the device - an AI powered personal voice assistant. Using this voice assistant, the user can pass verbal commands and use the various modules to perform specific actions. With an assistant to guide you and perform required tasks, you will never feel alone.
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/img_voiceass.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
