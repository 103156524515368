import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/Logo';
import FooterSocial from './partials/FooterSocial';
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

const Footer = ({
  className,
  topOuterDivider,
  topDivider,
  ...props
}) => {

  const classes = classNames(
    'site-footer center-content-mobile',
    topOuterDivider && 'has-top-divider',
    className
  );

  return (

    <footer
      {...props}
      className={classes}
    >

      <div className="container">
        <div className={
          classNames(
            'site-footer-inner',
            topDivider && 'has-top-divider'
          )}>
          <hr />
          <div className="footer-top space-between text-xxs" id="ContactUs">
            <Logo />
            <FooterSocial />
          </div>

          <div class="float-container">

          <div class="float-child">
          <b><div className="footer-copyright text-s" >Contact us</div></b>


          
          <div className="footer-copyright text-xs">
          
          <div class="float-container">
          <div class="float-child1">
          <FaEnvelope />
          </div>

          <div class="float-child1">
          aiviewstartup@gmail.com<br/>
          </div>
          </div>

          <br/><br/>
          
          <div class="float-container">
          <div class="float-child2">
          <FaPhoneAlt />
          </div>

          <div class="float-child2">
          +917013130419
          </div>
          </div>
          
          
          </div>
          </div>
          
          <div class="float-child">
          <b><div className="footer-copyright" >Current Team</div></b>
          <div className="footer-copyright text-xs">Chappa Sri Vinay<br/>
          Ishan Bansal<br/><br/>
          </div>
          <b><div className="footer-copyright" >Past Team</div></b>
          <div className="footer-copyright text-xs">
          Rishika Bhatia<br/>
          Geetansh Saxena<br/>
          Saransh Chopra
          </div>
          </div>
        
          </div>

        </div>
      </div>
    </footer >
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
